import { Address } from "./address";
import { LegacyGeoLocationCoordinatesAndType } from "./util";
import { IOpenNegotiationsCount } from "../../../../app/rateNegotiation/interfaces";
import { AgentStage } from "../../../../appV2/Agents";

interface AgentOnboardingFlags {
  isSignupInitiated?: boolean;
  isSignupCompleted?: boolean;
  signupStage?: string;
}

export type ClipboardAdvanceStateType =
  | "INTERESTED"
  | "SUBMITTED_PAYSTUBS"
  | "ENROLLED"
  | "UNENROLLED";

interface License {
  number?: string;
  expires?: string;
  state?: string;
}

interface AgentAttendance {
  sortMeta?: 0 | 1;
  completedShifts: number;
  cancelledShifts?: number;
  totalShifts?: number;
  consecutive?: number;
  consecutiveCancels?: number;
  percentage?: number;
  updatedAt?: string;
}

interface AgentRequirement {
  requirement?: string;
  expires?: string;
}

interface AgentUploads {
  requirement?: string;
  status?: string;
}

interface Specialities {
  experience?: string[];
  hasSedationExperience?: boolean;
  hasTrayAssemblyExperience?: boolean;
}

interface AgentRating {
  count?: number;
  value?: number;
}

interface NotificationPreference {
  off: boolean; // deprecated field
  push: boolean;
  email: boolean;
  sms: boolean;
  marketingPush: boolean;
  marketingEmail: boolean;
  marketingSMS: boolean;
  long_code_sms: boolean; // deprecated field
  urgentShiftNotification?: {
    isOn: boolean;
    snoozedOn: Date | null;
    snoozedForDays: number | null;
  };
  priorityAccessPush?: boolean;
}

interface AgentPreference {
  distance?: number;
  qualification?: string;
  recommendedDistance?: number;
}

interface AppRating {
  appRatingSubmitted?: boolean;
  countPromptShown: number;
  appRating?: string;
  countCompletedShiftsWhenLastShownPrompt: number;
  lastTimePromptWasShown?: Date;
}
export enum CONTRACTOR_AGREEMENT_VERSIONS {
  V1 = "V1",
  V2 = "V2",
  V3 = "V3",
  V4 = "V4",
  V5 = "V5",
}
interface ContractorAgreement {
  agreementVersion: CONTRACTOR_AGREEMENT_VERSIONS;
  signedDate: string;
  fullName?: string | null;
}

interface BackgroundCheckApplication {
  applicationType?: string;
  candidateId?: string;
}
interface Mobile {
  openedAt?: Date;
  version?: string;
  build?: number;
  platform?: string;
  isLocationServicesEnabled?: boolean;
  locationServicesPermission?: string;
}

interface IAgentLicensesData {
  qualification: string;
  state: string;
  multiState: boolean;
  expiresAt?: Date;
}
interface ILicensesCount {
  PENDING?: number;
  ACTIVE?: number;
  REJECTED?: number;
  EXPIRING?: number;
  EXPIRED?: number;
}

interface Agent {
  _id?: string;
  active?: boolean;
  userId?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  qualification?: string;
  geoLocation?: LegacyGeoLocationCoordinatesAndType;
  address?: Address;
  specialities?: Specialities;
  license?: License;
  hideAsColleagueOnShifts?: boolean;
  onboardingFlags?: AgentOnboardingFlags;
  socialSecurityNumber?: string;
  fullSocialSecurityNumber?: string;
  noCallNoShow?: number;
  attendance?: AgentAttendance;
  requirements?: AgentRequirement[];
  profileImageUrl?: string;
  isProfilePictureUploaded?: boolean;
  rating?: AgentRating;
  /**
   * This is always defined from the server, even during onboarding.
   */
  stage?: AgentStage;
  otherQualification?: string;
  referralCode?: string;
  showReferralAlert?: boolean;
  rate?: number;
  uploads?: AgentUploads[];
  isFirstSession?: boolean;
  isNotificationAlertShown?: boolean;
  isFirstTimeLoggedIn?: boolean;
  applicantId?: string;
  signupExperimentId?: string;
  signupConfig?: {
    signingBonusAmount1?: number;
    signingBonusAmount2?: number;
    eventName?: string;
  };
  hasClaimedBonus?: boolean;
  notificationPreferences?: NotificationPreference;
  appRatingStatus?: AppRating;
  status?: number;
  phoneNumber?: string | number;
  onboardAt?: string;
  lastLogAt?: string;
  preference?: AgentPreference;
  interestedAt?: object;
  canBeOnboardedInStripe?: boolean;
  alreadyOnboarded?: boolean;
  dob?: string;
  paymentAccountInfo?: PaymentAccountInfo;
  signedDate?: string;
  tmz?: string;
  alreadyWorked?: boolean;
  referralCodeUsed?: {
    code: string;
    createdAt: Date;
    referredBonusId?: string;
    referrerBonusId?: string;
    referrer: string;
  };
  signedContractorAgreements?: ContractorAgreement[];
  backgroundCheckApplications?: BackgroundCheckApplication[];
  mobile?: Mobile;
  isTestAccount?: boolean;
  requestedAccountDeletion?: {
    requested: boolean | null;
    createdAt: Date | null;
    previousStage: AgentStage | null;
  };
  licensesData?: IAgentLicensesData[];
  licensesCount?: ILicensesCount;
  clipboardAdvanceState?: ClipboardAdvanceStateType;
  negotiationsCount?: IOpenNegotiationsCount;
  createdAt?: string;
}

interface PaymentAccountInfo {
  _id: string;
  accountId: string;
  createdAt: string;
  enabled: boolean;
  enabledAt: string;
  updatedAt: string;
  autoWithdrawEnabled: boolean;
  withdrawalFee: number;
  status: PaymentServiceAccountStatus;
  showOnboard?: boolean;
  gatewayTransferDisabled: boolean;
  __v: number;
}

export enum PaymentServiceAccountStatus {
  ACCOUNT_CREATED = "Account Created",
  ACCOUNT_NEEDS_UPDATE = "Account Needs Updating",
  STANDARD_PAYOUT_PENDING = "Standard Payouts Pending Approval",
  INSTANT_PAYOUT_PENDING = "Instant Payouts Pending Approval",
  STANDARD_PAYOUT_ENABLED = "Standard Payouts Enabled",
  INSTANT_PAYOUT_ENABLED = "Instant Payouts Enabled",
  NO_DOCUMENTS_REQUIRED = "No Documents Required",
}

type SignUpStatus = "inprogress" | "complete" | "unstarted" | "unknown";

export type {
  Agent,
  AgentAttendance,
  License,
  AgentRequirement,
  Specialities,
  AgentUploads,
  NotificationPreference,
  PaymentAccountInfo,
  SignUpStatus,
  AgentPreference,
  ILicensesCount,
  IAgentLicensesData,
  AgentOnboardingFlags,
};
